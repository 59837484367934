.main-banner {
  width: 100%;
  height: 100%;
  position: relative; }
  .main-banner .banner-content {
    width: 80%;
    height: auto;
    background-color: rgba(3, 39, 91, 0.9);
    position: absolute;
    padding: 15px;
    top: 0;
    color: #fff; }
    @media (min-width: 992px) {
      .main-banner .banner-content {
        width: 60%;
        height: 185px;
        padding: 30px; } }
    .main-banner .banner-content h2 {
      text-transform: uppercase;
      font-size: 1.8rem;
      color: #fff; }
      @media (min-width: 992px) {
        .main-banner .banner-content h2 {
          font-size: 4.2rem; } }
    .main-banner .banner-content p {
      font-size: 1.2rem;
      letter-spacing: 0.2rem;
      font-weight: 300; }

.services li {
  width: 100%;
  float: left !important;
  padding: 0; }
  .services li .white-box {
    width: auto;
    height: 230px;
    transition: all 0.4s ease-in;
    border-top: 5px solid #fff;
    cursor: pointer; }
    @media (min-width: 768px) {
      .services li .white-box {
        border: 1px solid #ddd;
        width: 355px; } }
    .services li .white-box:hover {
      border-top-color: #fff; }
      @media (min-width: 992px) {
        .services li .white-box:hover {
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
          border-top-color: #00befc;
          border-top-width: 5px; } }

.our-services .bx-controls-direction {
  display: none; }
  @media (min-width: 992px) {
    .our-services .bx-controls-direction {
      display: block; } }
